import React, {useEffect, useState} from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Appbar, Badge, Icon, Chip} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";
import ProductSkeleton from "../components/skeleton/ProductSkeleton";
import Product from "../components/Product";
import {useSelector} from "react-redux";
import {selectCart} from "../slices/navSlice";

const TagScreen = ({route}) => {
    const {data} = route.params;
    const navigation = useNavigation();
    const [product, setProduct] = useState([]);
    const [loader, setLoader] = useState(true);
    const [tag, setTag] = useState(true);
    const cart = useSelector(selectCart)

    function onGet() {

        Axios.get("/products?tags=" + data.id)
            .then((res) => {
                console.log(res.data)
                setProduct(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })

    }


    useEffect(() => {

        onGet()
    }, [])

    return (
        <View>
            <Appbar.Header style={{backgroundColor: "white"}}>
                <Appbar.BackAction onPress={() => navigation.goBack()}/>
                <Appbar.Content title={data.name}/>
                <TouchableOpacity onPress={() => navigation.navigate("cartScreen")}>
                    <View>
                        <Badge size={18} style={{position: "absolute", top: -5, right: 4, zIndex: 1}}>{cart}</Badge>
                        <Icon source="cart" size={30}/>
                    </View>
                </TouchableOpacity>
            </Appbar.Header>
            {
                loader ?
                    <ProductSkeleton/>
                    : <FlatList
                        data={product}

                        numColumns={2}
                        keyExtractor={(item) => item.id}
                        renderItem={({item}) => {
                            return (
                                <Product data={item}
                                         onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)
                        }}
                    />
            }
        </View>
    );

}

export default TagScreen;
