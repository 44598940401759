import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, Dimensions, Pressable, FlatList, TouchableOpacity, Image} from 'react-native';
import Product from "./Product";
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";
import config from "../constants";
import {Button, Chip} from "react-native-paper";

const {width} = Dimensions.get("window")
const Event = ({data}) => {
    const navigation = useNavigation();
    const [product, setProduct] = useState([]);
    const [loader, setLoader] = useState(true);

    function onGet() {

        Axios.get("/products?type=" + data.id)
            .then((res) => {
                console.log(res.data)
                setProduct(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        onGet()
    }, [])

    return (
        <View style={{backgroundColor: "white", padding: 10, marginBottom: 10}}>
            <View style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Text className="text-xl font-semibold " style={{fontWeight: "bold"}}>{data.name}</Text>

                <Chip style={{backgroundColor: "#000"}}
                      textStyle={{color: "white"}}
                      onPress={() => navigation.navigate("eventScreen", {data: data})}
                >
                    Plus
                </Chip>
            </View>

            <View style={{
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 5,
                marginTop: 5
            }}>

                <Image source={{uri: config.server + "/uploads/events/" + data.logo}}
                       style={{
                           width: width - 20,
                           height: 150,
                           resizeMode: "cover"
                       }}
                />

            </View>
            <View>
                <FlatList
                    data={product}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => {
                        return (
                            <Product data={item}
                                     onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)
                    }}
                />
            </View>
        </View>
    )
}

export default Event
