import React from 'react';
import {Text, View, Image, Dimensions, StyleSheet, Pressable} from 'react-native';
import { Avatar } from 'react-native-paper';
import config from "../constants";

const {width} = Dimensions.get("window")
const CategoryCard = ({data, onPress}) => {

    return (

        <Pressable style={styles.container} onPress={() => onPress(data)}>
            <Avatar.Image size={60} source={{uri: config.server + '/uploads/logos/' + data?.logo}} />
            <View style={{
                padding: 10
            }}>
                <Text className="text-lg font-semibold text-center" style={{fontWeight: "bold"}}>{data.name}</Text>
            </View>
        </Pressable>
    );

}

export default CategoryCard;
const styles = StyleSheet.create({
    container: {
        alignItems:"center",
        width: width /4,
        margin:2

    }
})
