import React from 'react';
import {Text, View, Image, Dimensions, StyleSheet, Pressable} from 'react-native';
import config from "../constants";
import {Chip} from 'react-native-paper';

const {width} = Dimensions.get("window")
const Product = ({data, onPress}) => {

    return (

        <Pressable className="bg-white" style={styles.container} onPress={() => onPress(data)}>

            <Image
                style={{
                    height: 180,
                    width: width / 2 - 5,
                }}
                source={{uri: config.server + '/uploads/products/' + data?.cover}}/>
            <View style={{
                padding: 10
            }}>
                <Text className="text-lg font-semibold" style={{fontWeight: "bold"}}>${data.prix}</Text>
                <Text className="text-gray-500" style={{color: "gray"}}>{data.name}</Text>
            </View>

            {
                data.hasOwnProperty("tevents") && data.tevents.length > 0 &&
                <View style={{
                    flexDirection: "row"
                }}>
                    {
                        data.tevents.map((el, key)=>{
                            return <Chip key={key} mode="outlined">{el.reduce}%</Chip>
                        })
                    }

                </View>
            }



        </Pressable>
    );

}

export default Product;
const styles = StyleSheet.create({
    container: {
        height: width / 2 + 70,
        width: width / 2 - 5,
        margin: 2,
        backgroundColor: "white"
    }
})
