import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ScrollView, Image, Text} from 'react-native';
import {Appbar, Button, Dialog, Portal, Icon, TextInput} from 'react-native-paper';
import {useNavigation} from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ProductCart from "../components/ProductCart";
import {useDispatch, useSelector} from "react-redux";
import {selectCart, setCart} from "../slices/navSlice";
import Axios from "../api/Axios";
import config from "../constants";


const VoidCart = () => {
    const navigation = useNavigation();
    return <View style={{
        padding: 10,
        backgroundColor: "white",
        marginTop: 10,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    }}>
        <Image source={require("../assets/e.png")} style={{
            width: 200,
            height: 200
        }}/>
        <Text className="text-small font-semibold text-center">Oups!!!!! ton panier est vide</Text>
        <Button mode="contained" style={{margin: 20}} onPress={() => navigation.navigate("homeScreen")}
        >
            <Text className="text-center text-white text-xl">Fais tes courses</Text>
        </Button>
    </View>
}


const CartScreen = () => {
    const navigation = useNavigation();
    const [carts, setCarts] = useState([]);
    const [text, setText] = useState("");
    const [sum, setSum] = useState(0)
    const [city, setCity] = useState("Lubumbashi")
    const [address, setAddress] = useState()
    const [bill, setBill] = useState({})
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [phone, setPhone] = useState();
    const [area, setArea] = useState();
    const [ref, setRef] = useState();
    const [loader, setLoader] = useState();
    const [visible, setVisible] = React.useState(false);

    const showDialog = () => setVisible(true);


    const dispatch = useDispatch()

    async function getCart() {
        setCarts(JSON.parse(await AsyncStorage.getItem('cart')))
        const sum = JSON.parse(await AsyncStorage.getItem('cart')).reduce((accumulator, object) => {
            return accumulator + (object.prix * object.quantity);
        }, 0);
        setSum(sum)

        if (await AsyncStorage.getItem("userAddress")) {
            setBill(JSON.parse(await AsyncStorage.getItem("userAddress")))
            setArea(JSON.parse(await AsyncStorage.getItem("userAddress"))?.area)
            setAddress(JSON.parse(await AsyncStorage.getItem("userAddress"))?.address)
            setRef(JSON.parse(await AsyncStorage.getItem("userAddress"))?.ref)
        }
        if (await AsyncStorage.getItem('userId')) {
            setFirstName(await AsyncStorage.getItem('userFirstName'))
            setLastName(await AsyncStorage.getItem('userLastName'))
            setPhone(await AsyncStorage.getItem('userPhone'))

        }

        dispatch(setCart(JSON.parse(await AsyncStorage.getItem('cart')).length))

    }
    const hideDialog = () => {
        setVisible(false)
        getCart()
    };

    var removeByAttr = function (arr, attr, value) {

        console.log(arr)
        console.log(attr)
        console.log(value)
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {

                arr.splice(i, 1);

            }
        }
        return arr;
    }

    async function deleteCommand(id) {
        let cart = []
        cart = JSON.parse(await AsyncStorage.getItem("cart"))
        if (cart) {
            await AsyncStorage.setItem("cart", JSON.stringify(removeByAttr(cart, 'id_produit', id)))
            getCart()


        }
    }

    async function confirmCommand() {
        setLoader(true)

        Axios.post("/bill_address", {
            zip: "7110501",
            country: "RD-Congo",
            firstname: firstName,
            lastname: lastName,
            phone: phone,
            city: city,
            address: address

        })
            .then((r) => {
                console.log(r)
                let formData = new FormData();
                formData.append('bill_address', r.data.values);
                formData.append('commandes', JSON.stringify(carts));

                Axios.post("/commandes", {
                        bill_address: r.data.values,
                        commandes: JSON.stringify(carts)
                    },
                    {
                        headers: {
                            "Content-Type":
                                "application/json"
                        }
                    }
                ).then(async (res) => {
                    let cart = [];
                    await AsyncStorage.setItem('cart', JSON.stringify(cart));
                    dispatch(setCart(cart.length))
                    setLoader(false)
                    showDialog()
                }).catch((err) => {
                    setLoader(false)
                })

            })
            .catch((e) => {
                console.log(e)
                setLoader(false)
            })

    }

    useEffect(() => {
        getCart()
    }, [])


    return (
        <View style={styles.container}>
            <Appbar.Header>
                <Appbar.BackAction onPress={() => navigation.goBack()}/>
                <Appbar.Content title="Mon panier"/>
            </Appbar.Header>

            {
                carts.length > 0 ?
                    <View style={{flex: 1}}>
                        <ScrollView>
                            {
                                carts.map((el, key) => {
                                    return <ProductCart key={key} data={el} onDelete={deleteCommand}/>
                                })
                            }
                            <View style={{padding: 10, backgroundColor: "white", marginTop: 10}}>
                                <Text className="text-small font-semibold"
                                      style={{fontWeight: "bold", marginBottom: 10}}><Icon size={16}
                                                                                           source="location-outline"/> Indiquez
                                    nous vos information et votre adresse de livraison </Text>
                                <TextInput
                                    label="Prénom"
                                    value={lastName}
                                    onChangeText={text => setLastName(text)}
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                                <TextInput
                                    label="Nom"
                                    value={firstName}
                                    onChangeText={text => setFirstName(text)}
                                    style={{
                                        marginBottom: 10
                                    }}
                                />

                                <TextInput
                                    label="Numéro de téléphone"
                                    value={phone}
                                    onChangeText={text => setPhone(text)}
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                                <TextInput
                                    label="Ville"
                                    value={city}
                                    onChangeText={text => setCity(text)}
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                                <TextInput
                                    label="Adresse"
                                    value={address}
                                    onChangeText={text => setAddress(text)}
                                    style={{
                                        marginBottom: 10
                                    }}
                                    multiline
                                />


                            </View>

                        </ScrollView>

                        <Portal>
                            <Dialog visible={visible} onDismiss={hideDialog}>
                                <Dialog.Icon size={60} color={"green"} icon="checkmark-circle-outline"/>
                                <Dialog.Title style={{textAlign: "center", fontWeight: "bold"}}>Commande
                                    passé!!!!!</Dialog.Title>
                            </Dialog>
                        </Portal>

                        <View style={{
                            padding: 10,
                            backgroundColor: "white",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <View>
                                <Text style={{
                                    fontWeight: "bold",
                                    fontSize: 10
                                }}>Livraison: ${sum > 20 ? 0 : 4}</Text>
                                <Text style={{
                                    fontWeight: "bold",
                                    fontSize: 20
                                }}>${sum}</Text>
                            </View>

                            <Button mode="contained" loading={loader} onPress={confirmCommand}>
                                <Text>Confirmer</Text>
                            </Button>
                        </View>
                    </View>
                    : <VoidCart/>
            }

        </View>
    );

}

export default CartScreen;
const styles = StyleSheet.create(
    {
        container: {
            flex: 1
        }
    }
)
