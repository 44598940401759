import React, {useState, useEffect} from 'react';
import {Text, View, StyleSheet, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import {Appbar, Badge, Button, Icon, Card, List, Avatar} from "react-native-paper";
import {useSelector} from "react-redux";
import {selectCart} from "../slices/navSlice";
import {useNavigation} from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage';

const {width} = Dimensions.get('window');
const AccountScreen = () => {
    const cart = useSelector(selectCart)
    const navigation = useNavigation();
    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    });
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        onLoad();
    }, [navigation]);

    async function onLoad() {
        setInfo({
            firstName: await AsyncStorage.getItem('userFirstName'),
            lastName: await AsyncStorage.getItem('userLastName'),
            phone: await AsyncStorage.getItem('userPhone'),
            email: await AsyncStorage.getItem('userEmail'),
        });
    }

    async function logOut() {
        try {
            await AsyncStorage.clear();
            navigation.navigate("Home")
        } catch (error) {
        }
    }

    return (
        <View style={styles.container}>
            <Appbar.Header>
                <Appbar.Content title="Mon compte"/>
                <TouchableOpacity onPress={() => navigation.navigate("cartScreen")}>
                    <View>
                        <Badge size={18} style={{position: "absolute", top: -5, right: 4, zIndex: 1}}>{cart}</Badge>
                        <Icon source="cart" size={30}/>
                    </View>
                </TouchableOpacity>
            </Appbar.Header>


            {
                info.firstName ?
                    <>
                        <ScrollView

                            contentContainerStyle={{
                                alignItems: "center"
                            }
                            }>
                            <Card style={styles.boxProfile}>
                                <Card.Title
                                    title={info.lastName + ' ' + info.firstName}
                                    subtitle=""
                                    left={props => <Avatar.Icon {...props} icon="person"/>}
                                />
                            </Card>
                            <Card style={styles.boxProfile2}>
                                <List.Item
                                    title={info.email}
                                    left={props => <List.Icon {...props} icon="mail"/>}
                                />
                                <List.Item
                                    title={info.phone}
                                    left={props => <List.Icon {...props} icon="call"/>}
                                />
                            </Card>

                            {/*<Card style={styles.boxProfile2}>*/}
                            {/*    <List.Item*/}
                            {/*        title="Adresse physique"*/}
                            {/*        description=""*/}
                            {/*        left={props => <List.Icon {...props} icon="location-on"/>}*/}
                            {/*    />*/}
                            {/*</Card>*/}
                            {/*<Card style={styles.boxProfile2}>*/}
                            {/*    <List.Item*/}
                            {/*        title="Liste de souhait"*/}
                            {/*        description=""*/}
                            {/*        left={props => <List.Icon {...props} icon="favorite"/>}*/}
                            {/*    />*/}
                            {/*</Card>*/}
                        </ScrollView>
                        <Button mode="contained" onPress={logOut}>
                            Se deconnecter
                        </Button>
                    </>

                    : <View style={{padding: 10, backgroundColor: "white", marginTop: 10}}>
                        <Text className="text-small font-semibold">Oups!!!!! il semblerait que vous ne soyez pas
                            connecté</Text>
                        <Button mode="contained" style={{margin: 20}} className="bg-black py-2 m-4"
                                onPress={() => navigation.navigate("loginScreen")}>
                            <Text className="text-center text-white text-xl ">Se connecter</Text>
                        </Button>
                    </View>

            }


        </View>
    );

}

export default AccountScreen;
const styles = StyleSheet.create({
    container: {
        flex: 1,

    },
    boxProfile: {
        width: width - 10,
    },
    boxProfile2: {
        marginTop: 20,
        width: width - 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
        color: '#fff',
    },
});
