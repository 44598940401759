import React from 'react';
import {Text, View, Image, Dimensions, StyleSheet, Pressable, TouchableOpacity} from 'react-native';
import config from "../constants";
import {Chip, Icon, Avatar} from "react-native-paper";

const {width} = Dimensions.get("window")
const ProductCart = ({data, onDelete, onPress}) => {


    return (

        <View className="bg-white" style={styles.container}>
            <View style={{
                flexDirection: "row"
            }
            }>
                <Image
                    style={{
                        height: 100,
                        width: 100,
                    }}
                    source={{uri: data?.image}}/>
                <View style={{
                    padding: 10
                }}>
                    <Text className="text-lg font-semibold" style={{fontWeight: "bold"}}>${data.prix}</Text>
                    <View>
                        {
                            data.hasOwnProperty("colorDetail") &&
                            <Chip>
                                {
                                    data.colorDetail && <View style={{
                                        backgroundColor: data.colorDetail.values,
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5
                                    }}/>
                                }
                                {
                                    data.hasOwnProperty("sizeDetail") && "/" + data.sizeDetail.value
                                }
                            </Chip>
                        }


                    </View>
                    <Text className="text-gray-500" style={{color: "gray"}}>{data.name}</Text>
                </View>
            </View>


            <View style={{

                alignItems: "center"
            }
            }>
                <TouchableOpacity onPress={() => onDelete(data.id_produit)}>
                    <Icon size={20} source="trash"/>
                </TouchableOpacity>

                <View style={{
                    flexDirection: "row",
                    marginTop: 30
                }
                }>
                    <Icon size={20} source="add-circle-outline"/>
                    <Text>{data.quantity}</Text>
                    <Icon size={20} source="remove-circle-outline"/>
                </View>
            </View>


        </View>
    );

}

export default ProductCart;
const styles = StyleSheet.create({
    container: {
        height: 110,
        width: width,
        margin: 2,
        backgroundColor: "white",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10
    }
})
