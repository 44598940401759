import React from "react";
import {StyleSheet, View, Dimensions, ViewStyle} from "react-native";
import SkeletonLoader from "expo-skeleton-loader";

const {width, height} = Dimensions.get("window");


const ProductSkeleton = () => (
    <View style={{
        flexDirection: "row"
    }}>
        <SkeletonLoader style={{
            height: width / 2 + 70,
            width: width / 2 - 5,
            margin: 2,
            backgroundColor:"white"
        }}>

            <SkeletonLoader.Item
                style={{width, height: 200}}
            />

            <SkeletonLoader.Item
                style={{width: 60, height: 10, marginVertical: 10}}
            />
            <SkeletonLoader.Item
                style={{width: 120, height: 10, marginVertical: 10}}
            />


        </SkeletonLoader>
        <SkeletonLoader style={{
            height: width / 2 + 70,
            width: width / 2 - 5,
            margin: 2,
            backgroundColor:"white"
        }}>

            <SkeletonLoader.Item
                style={{width, height: 200}}
            />

            <SkeletonLoader.Item
                style={{width: 60, height: 10, marginVertical: 10}}
            />
            <SkeletonLoader.Item
                style={{width: 120, height: 10, marginVertical: 10}}
            />


        </SkeletonLoader>
    </View>

);

export default ProductSkeleton
