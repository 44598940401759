import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, ScrollView, Pressable, Image, TouchableOpacity, FlatList} from 'react-native';
import {Appbar, Badge, Chip, Icon} from 'react-native-paper';
import {List, MD3Colors} from 'react-native-paper';
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";
import config from "../constants";
import {useSelector} from "react-redux";
import {selectCart} from "../slices/navSlice";
import CategoryCard from "../components/CategoryCard";

const CategoryScreen = () => {
    const navigation = useNavigation();
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState({});
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const cart = useSelector(selectCart)

    function onGet() {

        Axios.get("/types")
            .then((res) => {
                console.log(res.data)
                setData(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        onGet()
    }, [])

    return (
        <View style={styles.container}>
            <Appbar.Header>
                <Appbar.Content title="Catégories"/>
                <TouchableOpacity onPress={() => navigation.navigate("cartScreen")}>
                    <View>
                        <Badge size={18} style={{position: "absolute", top: -5, right: 4, zIndex: 1}}>{cart}</Badge>
                        <Icon source="cart" size={30}/>
                    </View>
                </TouchableOpacity>
            </Appbar.Header>

            <View style={{
                padding: 5
            }}>
                <FlatList
                    data={data}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => <CategoryCard onPress={(data) => {
                        setDetail(data)
                        setTags(data.tags)
                    }} data={item}/>}/>
            </View>
            <ScrollView>
                <View style={{
                    padding: 10,
                    margin: 15,
                    borderRadius: 10,
                    backgroundColor: "#f3f1f1"
                }}>
                    <View style={{
                        alignItems: "center"
                    }}>
                        <Text className="text-lg font-semibold" style={{fontWeight: "bold"}}>{detail.name}</Text>
                    </View>


                    <List.Section style={{margin: 5}}>
                        {
                            tags && tags.map((el, key) => {
                                return <List.Item
                                    key={key}
                                    onPress={() => navigation.navigate("tagScreen", {data: el})}
                                    title={el.name}
                                    left={() => <List.Icon icon="star"/>}
                                    right={() => <List.Icon icon="chevron-forward-sharp"/>}

                                />
                            })
                        }

                    </List.Section>

                </View>

            </ScrollView>

        </View>
    );

}

export default CategoryScreen;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white"
    },
    box: {
        flexDirection: "row"
    },
    boxContainer1: {},
    boxContainer2: {}

})
