import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, Dimensions, Pressable, FlatList, TouchableOpacity, Image} from 'react-native';
import Product from "./Product";
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";
import config from "../constants";

const {width} = Dimensions.get("window")
const Arrivage = () => {
    const navigation = useNavigation();
    const [product, setProduct] = useState([]);
    const [loader, setLoader] = useState(true);

    function onGet() {

        Axios.get("/products?limite=10")
            .then((res) => {
                console.log(res.data)
                setProduct(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        onGet()
    }, [])

    return (
        <View style={{backgroundColor: "white", padding: 10, marginBottom: 10}}>
            <Text className="text-xl font-semibold " style={{fontWeight: "bold"}}>Récément ajouter</Text>
            <View>
                <FlatList
                    data={product}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => {
                        return (
                            <Product data={item}
                                     onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)
                    }}
                />
            </View>
        </View>
    )
}

export default Arrivage
