import React, {useState, useEffect} from 'react';
import {View, Alert, StyleSheet} from 'react-native';
import {TextInput, useTheme, Button, Appbar} from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';


const RegisterScreen = ({navigation}) => {
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loader, setLoader] = useState(false);

    const theme = useTheme();

    async function onCreate() {
        // setLoader(true);
        // let formData = new FormData();
        // formData.append('firstname', firstName);
        // formData.append('lastname', lastName);
        // formData.append('phone', phone);
        // formData.append('email', email);
        // formData.append('password', password);
        //
        // let res = await resources.auth.register(formData);
        //
        //
        //
        // if (res) {
        //     if (res.status === 200) {
        //         try {
        //             await AsyncStorage.setItem('userToken', res.data.token);
        //             await AsyncStorage.setItem('userEmail', res.data.user.email);
        //             await AsyncStorage.setItem('userPhone', res.data.user.phone);
        //             await AsyncStorage.setItem('userId', res.data.user.id.toString());
        //             await AsyncStorage.setItem('userFirstName', res.data.user.firstname);
        //             await AsyncStorage.setItem('userLastName', res.data.user.lastname);
        //             navigation.navigate('confirmStack');
        //         } catch (e) {
        //             console.log(e);
        //             Alert.alert('Trianta shop', 'Une erreur a été rencontrer');
        //         }
        //
        //         setLoader(false);
        //     }
        //     setLoader(false);
        // }
    }

    return (
        <View style={styles.container}>
            <Appbar.Header>
                <Appbar.BackAction onPress={() => navigation.goBack()}/>
                <Appbar.Content title="M'inscrire"/>
            </Appbar.Header>
            <View style={{justifyContent: "center"}}>
                <View style={styles.boxForm}>
                    <TextInput
                        style={styles.textInput}
                        label="Prénom"
                        value={lastName}
                        onChangeText={setLastName}
                    />
                    <TextInput
                        style={styles.textInput}
                        label="Nom"
                        value={firstName}
                        onChangeText={setFirstName}
                    />
                    <TextInput
                        style={styles.textInput}
                        label="Téléphone"
                        value={phone}
                        onChangeText={setPhone}
                    />
                    <TextInput
                        style={styles.textInput}
                        label="Adresse e-mail"
                        value={email}
                        onChangeText={setEmail}
                    />
                    <TextInput
                        style={styles.textInput}
                        label="Mot de passe"
                        value={password}
                        onChangeText={setPassword}
                    />
                    <Button
                        loading={loader}
                        mode="contained"
                        style={styles.btnLogin}
                        onPress={() => onCreate()}>
                        S'enregistrer
                    </Button>

                    <Button
                        mode="text"
                        style={styles.btnLogup}

                        onPress={() => navigation.navigate('loginScreen')}>
                        Se connecter
                    </Button>
                </View>
            </View>

        </View>
    );
};

export default RegisterScreen;
const styles = StyleSheet.create({
    container: {
        flex: 1,

    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
        color: '#000',
    },
    btnLogin: {

        height: 50,
        justifyContent: 'center',
    },
    btnLogup: {
        height: 50,
        justifyContent: 'center',
    },
    textInput: {
        height: 60,
        marginBottom: 15,
    },
    boxForm: {
        marginTop: 20,
        marginHorizontal: 20,
    },
});
