import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, SafeAreaView, FlatList} from 'react-native';
import {Searchbar} from 'react-native-paper';
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";
import ProductSkeleton from "../components/skeleton/ProductSkeleton";
import Product from "../components/Product";

const SearchScreen = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigation = useNavigation();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    function onChangeSearch(query) {
        setSearchQuery(query)
        setLoader(true)
        Axios.get("/products?keyword=" + query)
            .then((res) => {

                setData(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })

    }


    return (
        <SafeAreaView style={styles.container}>
            <Searchbar
                onIconPress={() => navigation.goBack()}
                loading={false}
                icon="arrow-back-sharp"
                placeholder="Recherche..."
                onChangeText={onChangeSearch}
                value={searchQuery}
            />
            {
                loader ?
                    <ProductSkeleton/>
                    : <FlatList
                        data={data}

                        numColumns={2}
                        keyExtractor={(item) => item.id}
                        renderItem={({item}) => {
                            return (
                                <Product data={item}
                                         onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)
                        }}
                    />
            }
        </SafeAreaView>
    );

}

export default SearchScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10
    }
})
