import {createSlice} from '@reduxjs/toolkit'

export interface navState {
    cart: number,
}

const initialState: navState = {
    cart: 0
}

export const navSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {

        setCart: (state, action) => {
            state.cart = action.payload
        },
    }
})

export const {setCart} = navSlice.actions
export const selectCart = (state) => state.cart
export default navSlice.reducer
