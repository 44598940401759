import React, {useState, useEffect} from 'react';
import {SafeAreaView, View, Text, StyleSheet, FlatList, TouchableOpacity, ScrollView} from "react-native";
import Header from "../components/Header";
import AppCarousel from "../components/AppCarousel";
import Product from "../components/Product";
import {useNavigation} from "@react-navigation/native"
import Axios from "../api/Axios";
import ProductSkeleton from "../components/skeleton/ProductSkeleton";
import Category from "../components/Category";
import Arrivage from "../components/Arrivage";
import Event from "../components/Event";


const HomeScreen = () => {
    const navigation = useNavigation();
    const [data, setData] = useState([]);
    const [event, setEvent] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loader, setLoader] = useState(true);

    function onGet() {

        Axios.get("/article?limit=300")
            .then((res) => {

                setData(res.data.items)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })


        Axios.get("/category?limit=300")
            .then((res) => {

                setCategories(res.data.items)


            })
            .catch((err) => {
                console.log(err)
            })

        Axios.get("/events?limit=300&isActive=1")
            .then((res) => {

                setEvent(res.data.items)


            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        onGet()
    }, [])

    return (
        <View style={styles.container}>
            <Header/>
            <ScrollView>
                <Arrivage/>
                {
                    event && event.map((el, key) => {
                        return <Event key={key} data={el}/>
                    })
                }
                {
                    categories.map((el, key) => {
                        return <Category key={key} data={el}/>
                    })
                }

            </ScrollView>

            {/*{*/}
            {/*    loader ?*/}
            {/*        <ProductSkeleton/>*/}
            {/*        : <FlatList*/}
            {/*        data={data}*/}

            {/*        numColumns={2}*/}
            {/*        keyExtractor={(item) => item.id}*/}
            {/*        renderItem={({item}) => {*/}
            {/*            return (*/}
            {/*                <Product data={item}*/}
            {/*                         onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)*/}
            {/*        }}*/}
            {/*    />*/}
            {/*}*/}


        </View>
);

}

export default HomeScreen;

const styles = StyleSheet.create(
    {
        container: {
            flex: 1
        }
    }
)
