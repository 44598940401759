import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, Dimensions, Pressable, FlatList, TouchableOpacity} from 'react-native';
import {Appbar, useTheme} from 'react-native-paper';
import {Icon, Badge} from 'react-native-paper';
import Axios from "../api/Axios";
import {useNavigation} from "@react-navigation/native";
import {useDispatch, useSelector} from "react-redux";
import {selectCart, setCart} from "../slices/navSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";

const {width} = Dimensions.get("window")
const Header = () => {
    const {colors} = useTheme()
    const navigation = useNavigation();
    const cart = useSelector(selectCart)
    const dispatch = useDispatch()



    async function getCart() {
        let cart = [];
        cart = JSON.parse(await AsyncStorage.getItem('cart'));
        if (!cart) {
            cart = [];
        }
        dispatch(setCart(cart.length))

    }

    useEffect(() => {

        getCart()
    }, [])


    return (
        <View className="bg-white h-[150]" style={styles.container}>

            <View style={styles.nav} className="mt-5">
                <TouchableOpacity onPress={() => navigation.navigate("searchScreen")}>
                    <View style={styles.navSearBar} className="bg-gray-200">
                        <Text style={{
                            padding: 5
                        }
                        }>
                            Bonjour, que recherhez vous...
                        </Text>
                        <View style={styles.navButton}>
                            <Icon
                                source="md-search-outline"
                                color={"white"}
                                size={25}
                            />
                        </View>

                    </View>
                </TouchableOpacity>


                <TouchableOpacity onPress={() => navigation.navigate("cartScreen")}>
                    <View>
                        <Badge size={18} style={{position: "absolute", top: -5, right: 4, zIndex: 1}}>{cart}</Badge>
                        <Icon source="cart" size={30}/>
                    </View>
                </TouchableOpacity>


            </View>

        </View>
    );

}

export default Header;

const styles = StyleSheet.create({
    container: {
        backgroundColor: "white",
        marginBottom: 5
    },
    nav: {
        flexDirection: "row",
        padding: 10,
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 40
    },
    navSearBar: {
        height: 35,
        width: width - 70,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",

    },
    navButton: {
        backgroundColor: "black",
        height: 35,
        width: 40,
        alignItems: "center",
        justifyContent: "center",
    }
})
