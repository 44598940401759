import React, {useState, useContext} from 'react';
import {View, Alert, StyleSheet, Text, Image} from 'react-native';
// import { Btn } from '../ui';
// import { theme } from '../constant';
import {TextInput, useTheme, Button, Appbar} from 'react-native-paper';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from "@react-navigation/native";
import Axios from "../api/Axios";

const LoginScreen = () => {
    const [phone, setPhone] = useState('+243894591544');
    const [password, setPassword] = useState();
    const [loader, setLoader] = useState(false);
    const theme = useTheme();
    const navigation = useNavigation();

    function onLogin() {
        setLoader(true);
        let formData = new FormData();
        formData.append('username', phone);
        formData.append('password', password);
        Axios.post("/login", formData)
            .then(async (res) => {
                await AsyncStorage.setItem('userToken', res.data.token);
                await AsyncStorage.setItem('userEmail', res.data.user.email);
                await AsyncStorage.setItem('userPhone', res.data.user.phone);
                await AsyncStorage.setItem('userId', res.data.user.id.toString());
                await AsyncStorage.setItem('userFirstName', res.data.user.firstname);
                await AsyncStorage.setItem('userLastName', res.data.user.lastname);
                setLoader(false);
                navigation.goBack()
            })
            .catch((er) => {
               alert('Adresse e-mail ou mot de passe invalide');
                setLoader(false);
            })

    }

    return (
        <View style={styles.container}>
            <Appbar.Header>
                <Appbar.BackAction onPress={() => navigation.goBack()}/>
                <Appbar.Content title="Connexion"/>
            </Appbar.Header>
            <View style={{
                marginTop: 100,
            }}>
                <View
                    style={{
                        alignItems: "center"
                    }}
                >
                    <Image
                        source={require("../assets/logo.png")}
                        style={{
                            width: 265,
                            height: 130,
                            resizeMode: "cover",
                            marginBottom: 5
                        }}

                    />
                </View>


                <View style={styles.boxForm}>
                    <TextInput
                        style={styles.textInput}
                        label="Numéro de téléphone"
                        value={phone}
                        onChangeText={setPhone}
                    />
                    <TextInput
                        style={styles.textInput}
                        label="Mot de passe"
                        value={password}
                        onChangeText={setPassword}
                    />
                    <Button
                        loading={loader}
                        mode="contained"
                        style={styles.btnLogin}
                        onPress={() => onLogin()}>
                        Connexion
                    </Button>

                    <Button
                        mode="text"
                        style={styles.btnLogup}
                        color={theme.colors.primary}
                        onPress={() => navigation.navigate('registerScreen')}>
                        Inscription
                    </Button>
                </View>
            </View>

        </View>
    );
};

export default LoginScreen;
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
        color: '#000',
    },
    btnLogin: {

        height: 50,
        justifyContent: 'center',
    },
    btnLogup: {
        height: 50,
        justifyContent: 'center',
    },
    textInput: {
        height: 60,
        marginBottom: 15,
    },
    boxForm: {
        marginHorizontal: 20,
    },
});
