import {StyleSheet, Text, View, KeyboardAvoidingView, Platform} from 'react-native';
import {Provider} from "react-redux"
import {SafeAreaProvider} from "react-native-safe-area-context";
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {MD3LightTheme as DefaultTheme, PaperProvider} from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {store} from "./store";
import HomeScreen from "./screens/HomeScreen";
import CategoryScreen from "./screens/CategoryScreen";
import CartScreen from "./screens/CartScreen";
import AccountScreen from "./screens/AccountScreen";
import DetailScreen from "./screens/DetailScreen";
import DetailCategoryScreen from "./screens/DetailCategoryScreen";
import SearchScreen from "./screens/SearchScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import EventScreen from "./screens/EventScreen";
import TagScreen from "./screens/TagScreen";


const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: '#000',
        secondary: 'yellow',

    },
    roundness: 0
};
const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();


const AppTab = () => {
    return (
        <Tab.Navigator
            screenOptions={({route}) => ({
                cardStyle: {flex: 1},
                tabBarActiveTintColor: theme.colors.primary,
                tabBarInactiveTintColor: 'gray',
                tabBarIcon: ({focused, color, size}) => {
                    let iconName;

                    if (route.name === 'Home') {
                        iconName = focused ? 'home' : 'home';
                    } else if (route.name === 'Categories') {
                        iconName = focused ? 'apps' : 'apps';
                    } else if (route.name === 'Account') {
                        iconName = focused ? 'person' : 'person';
                    }

                    return <Ionicons name={iconName} size={30} color={color}/>;
                },
            })}
        >
            <Tab.Screen
                name="Home"
                component={HomeScreen}
                options={
                    {
                        headerShown: false,
                        tabBarLabel: "Acheter"
                    }
                }/>
            <Tab.Screen
                name="Categories"
                component={CategoryScreen}
                options={
                    {
                        headerShown: false,
                        tabBarLabel: "Catégories"
                    }
                }/>

            <Tab.Screen
                name="Account"
                component={AccountScreen}
                options={
                    {
                        headerShown: false,
                        tabBarLabel: "Mon compte"
                    }
                }/>
        </Tab.Navigator>
    )
}

export default function App() {


    return (
        <Provider store={store}>
            <PaperProvider
                theme={theme}
                settings={{
                    icon: props => <Ionicons {...props} />,
                }}>
                <NavigationContainer>
                    <SafeAreaProvider>
                        <KeyboardAvoidingView
                            behavior={Platform.OS === "ios" ? "padding" : "height"}
                            keyboardVerticalOffset={Platform.OS === "ios" ? -64 : 0}
                            style={{
                                flex: 1
                            }
                            }>
                            <Stack.Navigator>
                                <Stack.Screen name="homeScreen" options={{header: () => null}} component={AppTab}/>
                                <Stack.Screen name="detailScreen" options={{header: () => null}}
                                              component={DetailScreen}/>
                                <Stack.Screen name="searchScreen" options={{header: () => null}}
                                              component={SearchScreen}/>
                                <Stack.Screen name="cartScreen" options={{header: () => null}}
                                              component={CartScreen}/>
                                <Stack.Screen name="detailCategoryScreen" options={{header: () => null}}
                                              component={DetailCategoryScreen}/>
                                <Stack.Screen name="eventScreen" options={{header: () => null}}
                                              component={EventScreen}/>
                                <Stack.Screen name="tagScreen" options={{header: () => null}}
                                              component={TagScreen}/>
                                <Stack.Screen name="loginScreen" options={{header: () => null}}
                                              component={LoginScreen}/>
                                <Stack.Screen name="registerScreen" options={{header: () => null}}
                                              component={RegisterScreen}/>

                            </Stack.Navigator>


                        </KeyboardAvoidingView>
                    </SafeAreaProvider>
                </NavigationContainer>
            </PaperProvider>
        </Provider>

    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
