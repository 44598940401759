import React, {useEffect, useState} from 'react';
import {
    Text,
    View,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Pressable,
    FlatList,
    Image
} from 'react-native';
import {Appbar, Snackbar, Card, Chip, Button, Badge, Icon} from 'react-native-paper';
import {useNavigation} from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Product from "../components/Product";
import config from "../constants";
import Axios from "../api/Axios";
import {useDispatch, useSelector} from "react-redux";
import {selectCart, setCart} from "../slices/navSlice";


const {width} = Dimensions.get("window")
const DetailScreen = ({route}) => {
    const {data} = route.params;
    const navigation = useNavigation();
    const [colorSelect, setColorSelect] = useState();
    const [sizeSelect, setSizeSelect] = useState();
    const [visible, setVisible] = React.useState(false);
    const [message, setMessage] = useState();
    const onToggleSnackBar = (m) => {
        setMessage(m)
        setVisible(!visible)
    };
    const onDismissSnackBar = () => setVisible(false);
    const [product, setProduct] = useState([]);
    const [loader, setLoader] = useState(true);
    const cart = useSelector(selectCart)
    const dispatch = useDispatch()
    const [sizes, setSizes] = useState([]);

    function onGet() {

        if (data.hasOwnProperty("colorSizes")) {
            if (data.colorSizes.length > 0) {
                setSizes(data?.colorSizes[0].sizes)
                setColorSelect(data?.colorSizes[0])
            }
        }

        Axios.get("/products?type=" + data.type.id)
            .then((res) => {
                console.log(res.data)
                setProduct(res.data)
                setLoader(false)

            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        onGet()
    }, [])

    function onSelectColor(el) {
        setColorSelect(el)
        setSizes(el.sizes)

    }

    function onSelectSize(el) {
        setSizeSelect(el)
    }


    async function addProductToCart() {


        let cart = [];
        let c = {
            id_produit: data?.id,
            image: config.server + '/uploads/products/' + data?.cover,
            prix: data?.prix,
            name: data?.name,
            quantity: 1,
        }

        cart = JSON.parse(await AsyncStorage.getItem('cart'));
        if (!cart) {
            cart = [];
        }

        if (data.colorSizes.length > 0) {
            if (!colorSelect) {
                alert("Vous devez choisir une couleur")
                return
            }
            c.colorDetail = colorSelect
            c.color = colorSelect.id
        }

        if (data.colorSizes.length > 0) {
            if (!sizeSelect) {
                alert("Vous devez choisir une taille")
                return
            }
            c.sizeDetail = sizeSelect
            c.size = sizeSelect.id
        }

        cart.push(c);
        try {
            await AsyncStorage.setItem('cart', JSON.stringify(cart));
            dispatch(setCart(cart.length))
            onToggleSnackBar("Produit ajouter au panier");
        } catch (e) {
            console.log('erreur:' + e);
            onToggleSnackBar("Une erreur est sruvenue");
        }
    }

    return (
        <View style={styles.container}>
            <Appbar.Header style={{backgroundColor: "white"}}>
                <Appbar.BackAction onPress={() => navigation.goBack()}/>
                <Appbar.Content title={data.name}/>
                <TouchableOpacity onPress={() => navigation.navigate("cartScreen")}>
                    <View>
                        <Badge size={18} style={{position: "absolute", top: -5, right: 4, zIndex: 1}}>{cart}</Badge>
                        <Icon source="cart" size={30}/>
                    </View>
                </TouchableOpacity>
            </Appbar.Header>
            <ScrollView>
                <View style={{backgroundColor: "white"}}>
                    <Image
                        style={{
                            height: width,
                            width: width,
                            resizeMode: "cover"
                        }}
                        source={{uri: config.server + '/uploads/products/' + data?.cover}}/>

                    <View className={"py-2 m-3"} style={{padding: 10, backgroundColor: "white", marginTop: 10}}>
                        <Text className="text-lg font-semibold" style={{fontWeight: "bold"}}>${data.prix}</Text>
                        <Text className="text-gray-500">{data.name}</Text>
                    </View>


                </View>

                {
                    data.hasOwnProperty("colorSizes") && data.colorSizes.length > 0 &&

                    <View style={{padding: 10, backgroundColor: "white", marginTop: 10}}>


                        <Text className="text-small font-semibold " style={{fontWeight: "bold"}}>Couleur</Text>
                        <View style={{
                            flexDirection: "row"
                        }
                        }>
                            {
                                data?.colorSizes.map((el, key) => {
                                    return <Chip selected={colorSelect?.id == el.id ? true : false} mode={"outlined"}
                                                 key={key} style={{marginRight: 3}}
                                                 onPress={() => onSelectColor(el)}>
                                        <View style={{
                                            backgroundColor: el.values,
                                            width: 20,
                                            height: 20,
                                            borderRadius: 10
                                        }}/>
                                    </Chip>
                                })
                            }
                        </View>

                        <Text className="text-small font-semibold mt-5" style={{fontWeight: "bold"}}>Taille</Text>

                        <View style={{
                            flexDirection: "row"
                        }
                        }>
                            {
                                sizes.map((el, key) => {
                                    return <Chip selected={sizeSelect?.id == el.id ? true : false} mode={"outlined"}
                                                 key={key} style={{marginRight: 3}}
                                                 onPress={() => onSelectSize(el)}>{el.value}</Chip>
                                })
                            }


                        </View>
                    </View>
                }
                <View style={{padding: 10, backgroundColor: "white", marginTop: 10}}>
                    <Text className="text-small font-semibold" style={{fontWeight: "bold"}}>Description</Text>
                    <Text className="text-gray-500">{data.description}</Text>

                </View>

                <View style={{padding: 10, backgroundColor: "white", marginTop: 10}}>
                    <Text className="text-small font-semibold" style={{fontWeight: "bold"}}>Vous aimerez surement</Text>

                </View>
                <FlatList
                    data={product}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => {
                        return (
                            <Product data={item}
                                     onPress={(data) => navigation.navigate("detailScreen", {data: data})}/>)
                    }}
                />


            </ScrollView>

            <View style={styles.boxBottom}>


                <Button mode="contained" onPress={addProductToCart} style={{
                    margin: 20
                }}>
                    <Text className="text-center text-white text-xl ">Ajouter au panier</Text>
                </Button>
                <Snackbar
                    style={{
                        zIndex: 10000
                    }}
                    visible={visible}
                    onDismiss={onDismissSnackBar}
                    action={{
                        label: 'ok',
                        onPress: () => onDismissSnackBar(),
                    }}>
                    {message}
                </Snackbar>

            </View>
        </View>
    );

}

export default DetailScreen;
const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    boxBottom: {
        backgroundColor: "white"
    },
    boxImage: {},

})
